@import url(./../../fonts/font.css);

@media screen and (min-width: 320px){
  .footer{
    height: auto;
    background-color: #191919;
    color: #fff;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  .main{
    width: 90%;
    margin: auto;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .main h1{
    font-size: 18px;
    padding-bottom: 1rem;
    font-family: "gilroyBold";
    text-align: left;
  }
  .footer p{
    font-family: "gilroyLight";
    font-size: 14px;
    text-align: left;
  }
  .sub-main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; 
  }
   
  .cred p{
    text-align: center;
  }
  .icons{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .icons a{
    margin-right: 1rem;
  }
}
@media screen and (min-width: 1440px){
  .main{
    width: 70%;
    margin: auto;
    justify-content: space-between;
    display: flex;
    flex-direction: row;

  }
  .main h1{
    font-size: 1rem;
    padding-bottom: 1rem;
    font-family: "gilroyBold";
    text-underline-offset: 8px;
    text-decoration-color: #21379b;
  }
  .footer p{
    font-family: "gilroyLight";
    font-size: 16px;
  }
  .sub-main{
    display: flex;
    flex-direction: column;
    text-align: left;
  }
}
/* .footer{
    height: auto;
    background-color: #191919;
    color: #fff;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .main{
    width: 70%;
    margin: auto;
    justify-content: space-between;
    display: flex;
    flex-direction: row;

  }
  .main h1{
    font-size: 1rem;
    padding-bottom: 1rem;
    font-family: "gilroyBold";
    text-underline-offset: 8px;
    text-decoration-color: #21379b;
  }
  .footer p{
    font-family: "gilroyLight";
    font-size: 16px;
  }
  .sub-main{
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .icons{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .icons a{
    margin-right: 1rem;
  } */
