/* event cards*/
.cards{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: auto;
      grid-gap: .5rem;
      width: 80%;
      margin: auto;
      margin-top: 4rem;     
    }
    /* responsive code */
@media (min-width: 320px) {
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
    .cards{
      display: grid;
      grid-auto-rows: auto;
      grid-gap: .5rem;
      width: 80%;
      margin: auto;
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      margin-top: 4rem;
      margin-right: 4rem;
    }
}

@media (min-width: 1024px) {
    .cards{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: .5rem;
        width: 95%; 
        margin: auto;
        margin-top: 4rem;   
    }
  }
  @media (min-width: 1440px){
     .cards{
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        align-items: center;
     }
  }
.card-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.card-timer{
   margin: auto;
}
.button {
    display: inline-block;
    border-radius: 4px;
    background-color: #000000;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    padding: 0.8rem;
    width: 100%;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
  }
  
  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .button:hover span {
    padding-right: 25px;
  }
  
  .button:hover span:after {
    opacity: 1;
    right: 0;
  }






