@import url(./../../fonts/font.css);

*, *::after, *::before {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
  }
  
  html, body {
    height: 100vh;
    width: 100%;
  }
  
  /* body {
    color: #202046;
    font-family:"gilroyLight", "Inter", sans-serif;
  } */
  
  .App {
    background-image: url(./../../img/pattern-curve.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 60%;
    padding-bottom: 1rem;
  }
  
  .site-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100vh;
    width: 100%;
    margin: 5vh auto;
  }
  
  section {
    width: 370px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  p {
    font-weight: 300;
    line-height: 1.5;
    margin: 1rem 0;
  }
  
  #image-container {
    background-image: url(./../../img/pattern-bg.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 45%;
    margin-bottom: 2.5vh;
    position: relative;
  }
  
  .testimonial-img {
    border-radius: 5px;
    width: 80%;
  }
  
  .btn-container {
    position: absolute;
    bottom: 7%;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background: #fff;
    border-radius: 25px;
    -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    cursor: pointer;
    padding: 0.5rem;
    height: 35px;
    width: 70px;
  }
  
  #testimonial-container {
    background-image: url(./../../img/pattern-quotes.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 20%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    padding: 2.5vh 10px;
  }
  
  .title {
    display: block;
    color: #babacf;
  }
  
  @media screen and (min-width: 1000px) {
    .site-container {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      max-width: 1200px;
      padding: 0 50px;
      margin: 0 auto;
    }
    section {
      height: 550px;
      width: 575px;
    }
    #image-container {
      height: 550px;
      min-width: 575px;
      margin: 0;
    }
    .testimonial-img {
      width: 440px;
    }
    .btn-container {
      left: 25%;
    }
    #testimonial-container {
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: start;
      background-position: 33% 20%;
      padding-left: 25px;
      font-family: "gilroyLight";
    }
    .testimonial-text {
      font-size: 32px;
      text-align: left;
      width: 130%;
      z-index: 3;
    }
    .title {
      display: inline;
      margin-left: 1rem;
    }
  }
  /*# sourceMappingURL=App.css.map */