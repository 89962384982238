@import url(./../../fonts/font.css);

@media screen and (min-width: 320px) {
    .banner {
      width: 98%;
      margin: auto;
      display: flex;
      flex-direction: column;
    }
    .left{
        width: 98%;
        margin: auto;
    }
    .banner-carousel{
        width: 95vw;
        height: auto;
        margin: auto;
    }
    .left h1{
        font-family: "monumentUltraBold";
        font-size: 1.4rem;
        text-align: center;
    }
    .left p{
        text-align: justify;
        font-size: 16px;
    }
  }
  @media screen and (min-width: 375px){
    .left h1{
        font-family: "monumentUltraBold";
        font-size: 1.6rem;
        text-align: center;
    }
  }

  @media screen and (min-width: 425px){
    .left h1{
        font-family: "monumentUltraBold";
        font-size: 1.8rem;
        text-align: justify;
    }
  }
  @media screen and (min-width: 1024px){
    .left h1{
        font-family: "monumentUltraBold";
        font-size: 2.2rem;
        text-align: left;
    }
    .banner {
        width: 95%;
        margin: auto;
        display: flex;
        flex-direction: row;
      }
      .left{
          width: 50%;
          margin: auto;
          margin-right: 1.5rem;
      }
      .banner-carousel{
          width: 40vw;
          height: auto;
          margin: auto;
          margin-right: 2rem;
      }
      .left p{
        text-align: justify;
        font-size: 18px;
    }
  }

  @media screen and (min-width: 1400px){
    .banner{
        width: 75%;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        margin-top: 7rem;
    }
    .left{
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         align-items: flex-start;
         width: 50%;
         margin-right: 4rem;
         text-align: left;
    }
    .left h1{
        font-family: "monumentUltraBold";
        font-size: 2.6rem;
    }
    .left p{
        font-family: 'gilroyLight';
    }
    .banner-carousel{
        /* float: right; */
        width: 30vw;
        height: auto;
    }
    .logo{
        margin: 1rem;
    }
  }


/* .banner{
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 25rem 0rem 20rem 0rem;
}
.left{
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     align-items: flex-start;
     width: 50%;
     margin-right: 4rem;
     text-align: left;
}
.left h1{
    font-family: "monumentUltraBold";
    font-size: 3rem;
}
.left p{
    font-family: 'gilroyLight';
}
.banner-carousel{
    /* float: right; */
    /* width: 30vw;
    height: auto;
}
.logo{
    margin: 1rem;
}  */
