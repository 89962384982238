@font-face {
    font-family: "monumentRegular";
    src: url(./MonumentExtended-Regular.otf) format("opentype");
  }
  @font-face {
    font-family: "monumentUltraBold";
    src: url(./MonumentExtended-Ultrabold.otf) format("opentype");
  }
  @font-face {
    font-family: "gitlroyBold";
    src: url(./Radomir\ Tinkov\ -\ Gilroy-ExtraBold.otf) format("opentype");
  }
  @font-face {
    font-family: "gilroyLight";
    src: url(./Radomir\ Tinkov\ -\ Gilroy-Light.otf) format("opentype");
  }